import React, { createContext, useContext, useEffect, useState } from "react";
import { addError } from "@jsq/observability";
import { REVEAL_SCRIPT, JQUERY_SCRIPT, BASE_ROUTE, JSQ_THEME_CONFIG } from "./constants";
interface DashboardHubContextType {
  isLoaded: boolean;
  error: string | null;
}

interface DashboardHubProviderProps {
  children: React.ReactNode;
  arenaId: string;
  userId: string;
}

const DashboardHubContext = createContext<DashboardHubContextType | undefined>(undefined);

const loadScript = async (
  script: typeof JQUERY_SCRIPT | typeof REVEAL_SCRIPT,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Check if script is already loaded
    if (document.querySelector(`script[src="${script.src}"]`) || script.verify()) {
      resolve();
      return;
    }

    const scriptEl = document.createElement("script");
    scriptEl.src = script.src;

    scriptEl.onload = () => {
      if (script.verify()) {
        resolve();
      } else {
        reject(new Error(`Verification failed for ${script.name}`));
      }
    };

    scriptEl.onerror = () => reject(new Error(`Failed to load ${script.name}`));
    document.head.appendChild(scriptEl);
  });
};

//create the custom theme
const createCustomRevealTheme = () => {
  if (!window.$?.ig?.RevealTheme) {
    throw new Error("RevealTheme not available");
  }
  const theme = new window.$.ig.RevealTheme();
  theme.fontColor = JSQ_THEME_CONFIG.fontColor;
  theme.accentColor = JSQ_THEME_CONFIG.accentColor;
  theme.useRoundedCorners = JSQ_THEME_CONFIG.useRoundedCorners;
  theme.chartColors = JSQ_THEME_CONFIG.chartColors;
  theme.visualizationMargin = JSQ_THEME_CONFIG.visualizationMargin;
  theme.dashboardBackgroundColor = JSQ_THEME_CONFIG.dashboardBackgroundColor;
  theme.regularFont = JSQ_THEME_CONFIG.regularFont;
  theme.mediumFont = JSQ_THEME_CONFIG.mediumFont;
  theme.boldFont = JSQ_THEME_CONFIG.boldFont;
  return theme;
};

export const DashboardHubProvider: React.FC<DashboardHubProviderProps> = ({
  children,
  arenaId,
  userId,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Handle errors when loading the dashboard
  const handleError = (err: Error) => {
    setError(err.message);
    addError(err.message);
    setIsLoaded(false);
  };

  useEffect(() => {
    const initializeReveal = async () => {
      try {
        await loadScript(JQUERY_SCRIPT);
        await loadScript(REVEAL_SCRIPT);
        // Set the custom theme
        if (window.$?.ig?.RevealSdkSettings) {
          window.$.ig.RevealSdkSettings.theme = createCustomRevealTheme();
          // Initialize Reveal route
          window.$.ig.RevealSdkSettings.enableActionsOnHoverTooltip = true;
          window.$?.ig?.RevealSdkSettings.setBaseUrl(`${window.location.origin}/${BASE_ROUTE}`);
          window.$?.ig?.RevealSdkSettings.setAdditionalHeadersProvider(() => ({
            "x-jsq-rprt-arena": arenaId,
            "x-jsq-rprt-user": userId,
          }));
        } else {
          throw new Error("RevealSdkSettings not available");
        }
        setIsLoaded(true);
      } catch (err) {
        handleError(
          err instanceof Error ? err : new Error("Failed to load scripts for DashboardHub"),
        );
      }
    };

    void initializeReveal();
  }, []);

  return (
    <DashboardHubContext.Provider value={{ isLoaded, error }}>
      {children}
    </DashboardHubContext.Provider>
  );
};

export const useDashboardHub = () => {
  const context = useContext(DashboardHubContext);
  if (context === undefined) {
    throw new Error("useDashboardHub must be used within a DashboardHubProvider");
  }
  return context;
};
